<template>
  <div class="brand">
    <Header></Header>
    <BaseBanner :banner="'brand'"></BaseBanner>
    <div class="brand_content website_wrapper">
      <BrandAttestationList></BrandAttestationList>
      <div ref="animation" @click="toDingWeiCloud()" :class="['brand_top_msg',brand_top_msg?'animation_right_start':'animation_opacity']">
        <p class="company_title">顶卫云科技</p>
        顶卫云科技是以技术研发、产品创新为核心、涵盖网站建设与推广、小程序与APP、互联网营销、大型系统软件开发，涉及到云计算、AI、大数据、移动互联网等技术领域
        包含了政企、新零售、在线教育、智能出行、新农业、工程管理、智慧城市、智慧制造、资产、档案管理等行业，为各行各业提供高质量的信息化解决方案，为传统企业数字化转型赋能。
      </div>
      <div :class="['brand_middle_msg',brand_top_msg?'animation_right_start':'animation_opacity']">
        <p class="company_title">顶岳科技</p>
        顶岳科技是一家专注于一二类医疗器械领域的创新企业，致力于为全球患者提供高品质、安全、可靠的健康产品。不仅拥有强大的研发团队和先进的生产设备，更具备全方位的销售渠道和服务体系。通过持续不断地推动产品创新和市场拓展。
      </div>
      <div :class="['brand_bottom_msg',brand_top_msg?'animation_right_start':'animation_opacity']">
        <p class="company_title">岳泰兴大药房</p>
        岳泰兴大药房作为岳泰兴医疗旗下的品牌，一直秉承着“顾客至上，服务至上”的经营理念，致力于打造全国领先的药品零售和医疗服务平台。其在产品质量、安全性、价格透明度等方面进行了全面的优化和升级，不断提升消费者的购物体验和健康管理水平。同时，岳泰兴药店借助数字化技术，构建起智能化的客户服务系统和供应链管理体系，为患者和医护人员提供更加便捷、高效、个性化的医疗服务，创造了更多的社会价值和商业价值。
      </div>
      <div class="brand_img_list">
        <SwiperImgList></SwiperImgList>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import BaseBanner from '@/components/BaseBanner'
import SwiperImgList from '@/components/SwiperImgList'
import BrandAttestationList from '@/components/brand/BrandAttestationList'
import {mixins} from '@/mixin'
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';

export default {
  name: 'Brand',
  components: {BaseBanner, SwiperImgList, BrandAttestationList, Header, Footer},
  mixins: [mixins],
  methods:{
    toDingWeiCloud(){
        window.open('https://dingweicloud.com', "_blank");
    }
  }
}
</script>

<style scoped lang="scss">
.brand {
  .brand_content {
    height: 1400px;
    position: relative;

    .brand_top_msg,
    .brand_bottom_msg,
    .brand_middle_msg {
      width: 940px;
      height: auto;
      line-height: 30px;
      color: #595959;
      font-size: 14px;
    }

    .company_title{
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 21px;
      //color: #000;
    }

    .brand_top_msg {
      position: absolute;
      top: 480px;
      left:8%;
      cursor: pointer;
    }
    .brand_top_msg:hover{
      color: #364a9c;
    }

    .brand_bottom_msg {
      //width: 1200px;
      position: absolute;
      top: 750px;
      left:8%;
    }

    .brand_middle_msg {
      position: absolute;
      top: 630px;
      left:8%;
    }

    .brand_img_list {
      position: absolute;
      bottom:18px;
      left: 0px;
      width: 1200px;
      height: auto;
    }

  }
}</style>