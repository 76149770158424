<template>
  <div ref="animation" :class="['brand_attestation_list',brand_attestation_list?'animation_right_start_deial':'animation_opacity']">
<!--    <img src="@/assets/img/brand/list1.jpg" alt="">-->
<!--    <img src="@/assets/img/brand/list2.jpg" alt="">-->
<!--    <img src="@/assets/img/brand/list3.jpg" alt="">-->
<!--    <img src="@/assets/img/brand/list4.jpg" alt="">-->
<!--    <img src="@/assets/img/brand/list5.jpg" alt="">-->
<!--    <img src="@/assets/img/brand/list6.jpg" alt="">-->
    <div class="text">重要合作伙伴</div>
    <div class="title">专业的技术团队与强大的合作伙伴助力企业高质量发展</div>
    <div class="middle">
      <div class="box">
        <img src="../../static/cooperation/albb.jpg">
        <div>阿里云</div>
      </div>
      <div class="box">
        <img src="../../static/cooperation/tengxun.jpg">
        <div>腾讯云</div>
      </div>
      <div class="box">
        <img style="width: 200px!important;" src="../../static/cooperation/azure.jpeg">
        <div>微软云</div>
      </div>
      <div class="box">
        <img src="../../static/cooperation/liantong.jpg">
        <div>联通云</div>
      </div>
      <div class="box">
        <img src="../../static/cooperation/huawei.jpg">
        <div>华为云</div>
      </div>
    </div>
    <div class="text_bottom">旗下三大品牌</div>
    <div class="title">致力于打造全国领先的智慧医疗品牌</div>
  </div>
</template>

<script>
import { mixins } from '@/mixin'
export default{
  name:'brandAttestationList',
  mixins:[mixins],
  data(){
    return({
      
    })
  }
}
</script>

<style scoped lang="scss">
.brand_attestation_list {
  position: absolute;
  top:70px;
  left:8%;
  width: 1200px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: space-between;
  .text{
    font-size: 32px;
    color: #000;
  }
  .text_bottom{
    color: #000;
    font-size: 30px;
  }
  .title{
    margin-top: 4px;
    font-size: 14px;
    color: rgb(182,161,161);
  }
  .middle{
    width: 900px;
    margin: 30px 0 80px 0;
    display: flex;
    justify-content: space-between;
    .box{
      display: flex;
      align-items: center;
      flex-direction: column;
      img{
        width:150px;
        height:120px;
      }
      div{
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }
}
.id{
  // box-sizing: border-box;
  // width: 100%;
  //width: 1200px;
  //height: 494px;
  //background: white;
  //padding: 100px 30px;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //margin: 0 auto;
  //box-sizing: border-box;
}
</style>